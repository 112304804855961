.Navbar01 {
  width: 100%;
  padding: 1rem;

  background-color: var(--clr-bg);
  border-bottom: solid 2px var(--clr-3);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.Navbar01-logo {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}
.Navbar01-logo img {
  width: 2rem;
}

.Navbar01-links {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}

/*-- responsive --*/

@media screen and (max-width: 1000px) {
  .Navbar01-links > li {
    display: none;
  }
}
