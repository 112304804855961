.Join01 {
  padding: 0 1rem;
}

.Join01-box {
  width: 100%;
  padding: 5rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;

  background-color: var(--clr-bg);
  border-radius: 8px;
}
.Join01-box > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
}

.Join01-box > img {
  width: 600px;
  margin-top: -8rem;
}

/*-- responsive --*/

@media screen and (max-width: 1300px) {
  .Join01-box > img {
    width: 600px;
    margin-top: unset;
  }
}
@media screen and (max-width: 1000px) {
  .Join01-box {
    width: 100%;
    padding: 2rem 1rem;

    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    text-align: center;

    background-color: var(--clr-bg);
    border-radius: 8px;

    overflow: hidden;
  }
  .Join01-box > div:nth-child(1) {
    margin-bottom: unset;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;
  }

  .Join01-box > img {
    width: 80%;
  }
}
