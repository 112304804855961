.About01 {
  padding: 5rem 1rem;

  background-color: var(--clr-1);
}

.About01-box {
  position: relative;
  width: 100%;

  padding: 2rem 5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: solid 1px var(--clr-3);
  border-radius: 8px;
}
.About01-box > img {
  position: absolute;
  top: -0.75rem;

  height: 1.5rem;
  padding: 0 1rem;

  background-color: var(--clr-1);
}

.About01-box > div {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  text-align: center;
}

/*-- responsive --*/

@media screen and (max-width: 1300px) {
  .About01-box {
    width: 100%;

    padding: 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .About01 {
    padding: 3rem 1rem 1rem;
  }
  .About01-box {
    width: 100%;

    padding: 2rem 1rem 1rem;
  }
}
