.HowTo01 {
  padding: 0 1rem 10rem;
}

.HowTo01-description {
  width: 100%;
  margin-bottom: 4rem;
}
.HowTo01-box {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 2rem));
  gap: 3rem;
  justify-content: center;
  align-items: start;
}

.HowTo01-box > div {
  width: 100%;
  height: 100%;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 0.5rem;

  background-color: var(--clr-bg);
  border: solid 2px var(--clr-3);
  border-radius: 5px;
}

/*-- responsive --*/

@media screen and (max-width: 1000px) {
  .HowTo01 {
    padding: 3rem 1rem 6rem;
  }
  .HowTo01-description {
    width: 100%;
    margin-bottom: 2rem;

    text-align: center;
  }
  .HowTo01-box {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}
