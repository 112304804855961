.Hero01 {
  padding: 8rem 1rem 0;

  background-color: var(--clr-1);
  background-image: url(../img/bg/bg-00.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  overflow: hidden;
}

.Hero01-box {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 5rem;
  justify-content: center;
  align-items: center;
}
.Hero01-box > .Hero01-box-text {
  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;
}
.Hero01-box > .Hero01-box-text > p.ca {
  padding: 8px;

  border: solid 1px var(--clr-2);
  border-radius: 8px;

  white-space: nowrap;
  overflow: hidden;
}

.Hero01-box > .Hero01-box-text > .btnFlex {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.Hero01-box > .Hero01-box-images {
  width: 100%;
  margin-bottom: -12rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Hero01-box > .Hero01-box-images > img {
  width: 420px;
  border: solid 2px var(--clr-3);
  border-radius: 1rem;
}
.Hero01-box > .Hero01-box-images > img:nth-child(1) {
  margin-right: -5rem;
}
.Hero01-box > .Hero01-box-images > img:nth-child(2) {
  z-index: 1;
}
.Hero01-box > .Hero01-box-images > img:nth-child(3) {
  margin-left: -5rem;
}

/*-- responsive --*/
@media screen and (max-width: 1300px) {
  .Hero01-box {
    gap: 2rem;
  }
  .Hero01-box > img {
    width: 320px;
  }
}

@media screen and (max-width: 1000px) {
  .Hero01 {
    padding: 3rem 1rem;
  }
  .Hero01-box {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  .Hero01-box > img {
    width: 90%;
  }
  .Hero01-box > .Hero01-box-text > p.ca {
    width: 100%;
  }

  .Hero01-box > .Hero01-box-images {
    width: 100%;
    margin-bottom: -50%;
  }
  .Hero01-box > .Hero01-box-images > img {
    width: 50%;
  }
}
