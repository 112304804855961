.Why01 {
  padding: 5rem 1rem 8rem;
}

.Why01-box {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 2rem));
  gap: 3rem;
  justify-content: center;
  align-items: start;
}

.Why01-box > div {
  width: 100%;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;

  border: solid 2px var(--clr-bg);
  border-radius: 8px;
}
.Why01-box > div > img {
  width: 4rem;
  aspect-ratio: 1/1;
  margin-top: -4rem;
  padding: 1rem;

  background-color: var(--clr-bg);
  border-radius: 8px;
}

/*-- responsive --*/

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1000px) {
  .Why01 {
    padding: 3rem 1rem 3rem;
  }
  .Why01-box {
    width: 100%;

    display: grid;
    grid-template-columns: 100%;
    gap: 1rem;
    justify-content: center;
    align-items: start;
  }
}
