.Token01 {
  padding: 8rem 1rem 0;
}

.Token01-box {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, calc(50% - 2.5rem));
  gap: 5rem;
  justify-content: center;
  align-items: start;
}

.Token01-box > div:nth-child(1) {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
}
.Token01-box > div:nth-child(2) {
  grid-column-start: 2;
  grid-column-end: 3;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 0.5rem;
}
.Token01-box > div:nth-child(2) > div {
  width: 100%;
  padding: 0.5rem 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  border: solid 2px var(--clr-3);
  border-radius: 8px;
}
.Token01-box > div:nth-child(2) > div.ca {
  background-color: var(--clr-3);

  overflow: hidden;
}

/*-- responsive --*/

@media screen and (max-width: 1000px) {
  .Token01 {
    padding: 3rem 1rem 0;
  }
  .Token01-box {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    text-align: center;
  }
  .Token01-box > div:nth-child(1) {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}
